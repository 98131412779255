import { useContext, useEffect, Fragment } from "react";
import { ParsedQuery } from "query-string";
import * as Sentry from "@sentry/react";
import Echo from "laravel-echo";
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme
} from "@mui/material/styles";

import { useMuiStyles, useRoutes } from "./hooks";
import GlobalStyles from "./GlobalStyles";
import RouterContent from "./RouterContent";

import ErrorBoundary, { ErrorBoundaryProps } from "../ErrorBoundary";
import { STEP_GROUP } from "../TutorialMode/types/enums";

import ParamsContext from "../../context/params/paramsContext";
import AuthContext from "../../context/auth/authContext";
import LanguageContext from "../../context/language/languageContext";
import TutorialContext from "../../context/tutorial/tutorialContext";
import { enUS, ltLT, ruRU, esES, ptBR, frFR } from "../../i18next/materialUi";
import {
    useWindowLocation,
    useUrlQueryParams,
    isAnalyticsCookieAccepted,
    PER_PAGE,
    NOT_FILTER_NAMES,
    LANGUAGES,
    getEnvironment,
    FILTER_NAMES,
    ROUTES
} from "../../shared";

const ErrorFallback = ({ error, resetError }: ErrorBoundaryProps) => {
    return <ErrorBoundary error={error} resetError={resetError} />;
};

const Routes = () => {
    const { filterDropdownOptions, setQueryParams, setResource } =
        useContext(ParamsContext);

    const { cookieConsent, is2FaEnableFlowSeen, user, checkAuth } =
        useContext(AuthContext);

    const { language } = useContext(LanguageContext);
    const { isInitialRender, setTutorialOpen } = useContext(TutorialContext);

    const { routes } = useRoutes();
    const { getUrlParams } = useUrlQueryParams();
    const { pathname, resource } = useWindowLocation();
    const { env, isNotLocalEnv } = getEnvironment();

    // false - because IT is blocking the traffic
    const isNotBlocking = false;

    const isAllowedToSend =
        isNotBlocking &&
        isNotLocalEnv &&
        isAnalyticsCookieAccepted(cookieConsent);

    if (isAllowedToSend) {
        Sentry.init({
            dsn: `https://5acd35fd067d4e07afe56d89e5460efd@${process.env.REACT_APP_SENTRY_DSN}`,
            tracesSampleRate: 1.0,
            environment: env
        });

        if (user && user.email) {
            Sentry.setUser({ email: user.email });
        }
    }

    const languages = {
        [LANGUAGES.English]: enUS,
        [LANGUAGES.Lithuanian]: ltLT,
        [LANGUAGES.Russian]: ruRU,
        [LANGUAGES.Spanish]: esES,
        [LANGUAGES.Portuguese]: ptBR,
        [LANGUAGES.French]: frFR
    };

    const currentLang = languages[language]
        ? languages[language]
        : languages[LANGUAGES.English];

    const muiThemeStyles = useMuiStyles();

    const theme = createTheme(muiThemeStyles, currentLang);

    useEffect(() => {
        const isUserConsentConfirmed = user?.user_consent === true;
        const isTutorialNotStarted = isInitialRender;

        const isCompanyOptionsLoaded =
            filterDropdownOptions[FILTER_NAMES.CompanyId] !== undefined;

        const showTutorial =
            isUserConsentConfirmed &&
            is2FaEnableFlowSeen &&
            isTutorialNotStarted &&
            isCompanyOptionsLoaded;

        showTutorial && setTutorialOpen(true, 0, STEP_GROUP.FirstTimeUser);
        // eslint-disable-next-line
    }, [user, is2FaEnableFlowSeen, isInitialRender, filterDropdownOptions]);

    useEffect(() => {
        initializeEcho();
        checkAuth();

        if (pathname !== ROUTES.Login) {
            const queryParamsFromUrl = getUrlParams();
            cleanUpQueryParams(queryParamsFromUrl);
        }

        return () => {
            removeEcho();
        };

        // eslint-disable-next-line
    }, []);

    const initializeEcho = () => {
        if (!window.Echo) {
            window.Pusher = require("pusher-js");

            window.Echo = new Echo({
                broadcaster: "pusher",
                key: "Tetra",
                cluster: "Tetra",
                forceTLS: true,
                wsHost: process.env.REACT_APP_BASE_URL_WSS,
                wssPort: 81
            });
        }
    };

    const removeEcho = () => {
        if (window.Echo) {
            window.Echo.disconnect();
            delete window.Echo;
        }
    };

    const cleanUpQueryParams = (queryParamsFromUrl: ParsedQuery<string>) => {
        Object.entries(queryParamsFromUrl).forEach(([id, value]) => {
            if (id === NOT_FILTER_NAMES.PerPage) {
                const correctValues: string[] = Object.values(PER_PAGE);

                if (
                    value === null ||
                    Array.isArray(value) ||
                    !correctValues.includes(value)
                ) {
                    queryParamsFromUrl[id] = "";
                }
            }

            if (value === null || id === FILTER_NAMES.RootCompany) {
                delete queryParamsFromUrl[id];
            }
        });

        const isResourceValid = routes
            .filter(({ isPrivate }) => isPrivate)
            .map(({ path }) => path)
            .includes(pathname as any);

        if (isResourceValid) {
            setQueryParams({
                params: {
                    ...queryParamsFromUrl
                }
            });

            setResource(resource);
        }
    };

    return (
        <Fragment>
            <GlobalStyles />

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Sentry.ErrorBoundary fallback={ErrorFallback}>
                        <RouterContent />
                    </Sentry.ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
        </Fragment>
    );
};

export default Routes;
